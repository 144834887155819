nav a {
    padding: 1rem;
    font-weight: bold;
    font-size: .8rem;
    color: var(--contrast);
}

#readerVideo {
    width: 100%;
    aspect-ratio: 4/1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
